import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LoginGuard} from './share/services/guards/login.guard';
import {NotFoundComponent} from './share/components/error/not-found/not-found.component';
import {PanelComponent as Panel} from './panel/panel.component';
import {AuthBaseComponent} from "./authentication/auth-base/auth-base.component";
import {RedirectComponent} from "./redirect/redirect.component";

const routes: Routes = [
  {path: '', redirectTo: 'auth/login', pathMatch: 'full'},
  {
    path: 'auth',
    component: AuthBaseComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./share/components/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./share/components/payment/payment.module').then((m) => m.PaymentModule),
  },
  {
    path: 'form',
    loadChildren: () => import('./formbuilder/formbuilder.module').then((m) => m.FormbuilderModule),
  },
  {
    path: 'portal',
    loadChildren: () => import('./portal/portal.module').then((m) => m.PortalModule),
  },
  {
    path: 'panel',
    component: Panel,
    loadChildren: () => import('./panel/panel.module').then((m) => m.PanelModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'app',
    loadChildren: () => import('./application/application.module').then((m) => m.ApplicationModule),
  },
  {
    path: 'redirect',
    component: RedirectComponent
  },
  {path: '**', component: NotFoundComponent},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
