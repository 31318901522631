import React, { useMemo } from "react";
import {
  BasicTextStyleButton,
  BlockTypeSelect,
  BlockTypeSelectItem,
  blockTypeSelectItems,
  ColorStyleButton,
  CreateLinkButton,
  DefaultReactSuggestionItem,
  FileCaptionButton,
  FileReplaceButton,
  FormattingToolbar,
  FormattingToolbarController,
  getDefaultReactSlashMenuItems,
  NestBlockButton,
  SuggestionMenuController,
  TextAlignButton,
  UnnestBlockButton,
  useCreateBlockNote,
} from "@blocknote/react";
import { BlockNoteView } from "@blocknote/mantine";
import {
  BlockNoteEditor,
  BlockNoteSchema,
  combineByGroup,
  defaultBlockSpecs,
  defaultInlineContentSpecs,
  filterSuggestionItems,
} from "@blocknote/core";
import { Mention } from "./mention";
import { EmployeeDto } from "../../data/objects/employee.dto";
import { localeFa } from "../locale.fa";
import { Alert } from "./alert";
import { RiAlertFill } from "react-icons/ri";
import { MdAutoFixHigh } from "react-icons/md";
import { localeEn } from "../locale.en";
import { UploaderService } from "../../share/services/uploader.service";
import { log } from "../../share/utils/logger";
import {
  getMultiColumnSlashMenuItems,
  locales as multiColumnLocales,
  multiColumnDropCursor,
  withMultiColumn,
} from "@blocknote/xl-multi-column";
import { ModalService } from "../../modal/modal.service";
import { SheetDto } from "../../data/objects/Sheet.dto";
import { SheetItemBaseDto } from "../../data/objects/sheet-item-base.dto";
import { Attribute } from "./attribute";
import {AttributeDto} from "../../data/objects/attribute.dto";

export const CustomBlockNoteEditor = (props) => {
  const lang = props.container.lang;
  const theme = props.container.theme;
  const id = props.container.id;
  const sheet: SheetDto = props.container.sheet;
  const sheetItem: SheetItemBaseDto = props.container.item;
  const uploader: UploaderService = props.container.uploader;
  const modalService: ModalService = props.container.modalService;
  const schema = BlockNoteSchema.create({
    inlineContentSpecs: {
      ...defaultInlineContentSpecs,
      mention: Mention,
      attribute: Attribute,
    },
    blockSpecs: {
      ...defaultBlockSpecs,
      alert: Alert,
    },
  });
  const upload = (file: File) => {
    return uploader.uploadPageFile(file);
  };
  const getMentionMenuItems = (editor): DefaultReactSuggestionItem[] => {
    return props.container.users.map((user: EmployeeDto) => ({
      title: `${user.FullName.firstName} ${user.FullName.lastName}`,
      onItemClick: () => {
        editor.insertInlineContent([
          {
            type: "mention",
            props: {
              user: `${user.FullName.firstName} ${user.FullName.lastName}`,
            },
          },
          " ", // add a space after the mention
        ]);
      },
      icon: user.Icon ? (
        <img style={{ height: "24px", width: "24px" }} src={user.Icon || ""} />
      ) : (
        <span style={{ height: "24px", width: "24px" }}>
          {user.FullName.firstName.substring(0, 1)}
        </span>
      ),
    }));
  };

  const getAttributeMenuItems = (editor): DefaultReactSuggestionItem[] => {
    return props.container.sheet.attributes.map((attr: AttributeDto) => ({
      title: `${attr.name}`,
      onItemClick: () => {
        editor.insertInlineContent([
          {
            type: "attribute",
            props: {
              sheetItem: sheetItem.id,
              sheet: sheet.id,
              attribute: attr?.code,
            },
          },
          " ",
        ]);
      },
    }));
  };



  let dictionary = { multi_column: multiColumnLocales.en, ...(lang == "fa" ? localeFa : localeEn) };
  const editor = useCreateBlockNote({
    schema: withMultiColumn(schema),
    dropCursor: multiColumnDropCursor,
    dictionary: dictionary,
    initialContent: props.container.content,
    uploadFile: upload,
  });
  const handleEditorChange = () => {
    const jsonData = editor.document;
    // editor.blocksToFullHTML(jsonData).then(html=>{
    if (props.container.editable) {
      window.dispatchEvent(
        new CustomEvent("editorChanged", { detail: { content: jsonData, id: id } }),
      );
    }
    // });
  };
  const extractStyles = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Collect all class names used in the HTML
    const classNames = new Set();
    tempDiv.querySelectorAll("[class]").forEach((element) => {
      element.classList.forEach((cls) => classNames.add(cls));
    });

    // Extract styles from all stylesheets
    let styles = "";
    for (const sheet of Array.from(document.styleSheets)) {
      try {
        for (const rule of Array.from(sheet.cssRules)) {
          if (rule instanceof CSSStyleRule) {
            if (
              rule.selectorText &&
              [...classNames].some((cls) => rule.selectorText.includes(`.${cls}`))
            ) {
              styles += rule.cssText + "\n";
            }
          }
        }
      } catch (e) {
        console.warn(`Could not access stylesheet: ${sheet.href}`, e);
      }
    }

    // Embed the styles in a <style> tag in the exported HTML
    const styleTag = `<style>${styles}</style>`;
    const finalHtml = `${styleTag}\n${htmlContent}`;

    return finalHtml;
  };
  window.addEventListener("editorExportHTML" + id, async () => {
    try {
      const html = await editor.blocksToFullHTML(editor.document);
      let styled = extractStyles(html);
      const link = document.createElement("a");
      const objectUrl = URL.createObjectURL(new Blob([styled], { type: "text/html" }));
      link.href = objectUrl;
      link.download = "download.html";
      link.click();
      URL.revokeObjectURL(objectUrl);
    } catch (e) {
      log(e);
    }
  });

  // window.addEventListener("editorExportPdf" + id, async () => {
  //   log("exporting");
  //   const exporter = new PDFExporter(editor.schema, {
  //     styleMapping: pdfDefaultSchemaMappings.styleMapping,
  //     inlineContentMapping: {
  //       ...pdfDefaultSchemaMappings.inlineContentMapping,
  //       mention: (inline, exporter) => {
  //         return inline.content;
  //       },
  //     },
  //     blockMapping: {
  //       ...pdfDefaultSchemaMappings.blockMapping,
  //       column: () => {},
  //       columnList: () => {},
  //       alert:()=>{}
  //     },
  //   });
  //   const pdfDocument = await exporter.toReactPDFDocument(editor.document);
  //   let result = await ReactPDF.renderToStream(pdfDocument);
  //   const chunks:any[] = [];
  //   for await (const chunk of result) {
  //     chunks.push(chunk);
  //   }
  //   let blob = new Blob(chunks,{type:'application/pdf'});
  //   saveAs(blob,'file.pdf');
  //
  // });

  const slashMenuItems = useMemo(() => {
    return combineByGroup(
      getDefaultReactSlashMenuItems(editor),
      getMultiColumnSlashMenuItems(editor),
    );
  }, [editor]);
  const getAskAIMenuItem = (editor: BlockNoteEditor) => ({
    title: "Ask AI",
    aliases: ["ask", "ai"],
    group: "Others",
    icon: <MdAutoFixHigh size={18} />,
    subtext: "",
    onItemClick: async () => {
      let ref = modalService.openAskAiDialog(await editor.blocksToHTMLLossy());
      ref.onClose.subscribe(async (response) => {
        const currentBlock = editor.getTextCursorPosition().block;
        let parsed = await editor.tryParseHTMLToBlocks(response);
        editor.insertBlocks(parsed, currentBlock, "after");
      });
    },
  });

  const getAttributeMenuItem = (editor:BlockNoteEditor) => ({
    title: "Sheet Property",
    aliases: ["attribute", "property"],
    group: "Others",
    icon: <MdAutoFixHigh size={18} />,
    subtext: "",
    onItemClick: () => {
      editor.openSuggestionMenu('&%#');
    },
  });
  const getCustomSlashMenuItems = (editor): DefaultReactSuggestionItem[] => {
    let items = [...slashMenuItems, getAskAIMenuItem(editor)];
    if(sheet){
      items.push(getAttributeMenuItem(editor));
    }
    return items;
  };

  return (
    <div className={"h-100 w-100"}>
      <BlockNoteView
        editor={editor}
        editable={props.container.editable}
        onChange={handleEditorChange}
        formattingToolbar={false}
        slashMenu={false}
      >
        <FormattingToolbarController
          formattingToolbar={() => (
            <FormattingToolbar>
              <BlockTypeSelect
                key={"blockTypeSelect"}
                items={[
                  ...blockTypeSelectItems(editor.dictionary),
                  {
                    name: "Alert",
                    type: "alert",
                    icon: RiAlertFill,
                    isSelected: (block) => block.type === "alert",
                  } satisfies BlockTypeSelectItem,
                ]}
              />

              <FileCaptionButton key={"fileCaptionButton"} />
              <FileReplaceButton key={"replaceFileButton"} />
              <BasicTextStyleButton basicTextStyle={"bold"} key={"boldStyleButton"} />
              <BasicTextStyleButton basicTextStyle={"italic"} key={"italicStyleButton"} />
              <BasicTextStyleButton basicTextStyle={"underline"} key={"underlineStyleButton"} />
              <BasicTextStyleButton basicTextStyle={"strike"} key={"strikeStyleButton"} />
              {/* Extra button to toggle code styles */}
              <BasicTextStyleButton key={"codeStyleButton"} basicTextStyle={"code"} />

              <TextAlignButton textAlignment={"left"} key={"textAlignLeftButton"} />
              <TextAlignButton textAlignment={"center"} key={"textAlignCenterButton"} />
              <TextAlignButton textAlignment={"right"} key={"textAlignRightButton"} />

              <ColorStyleButton key={"colorStyleButton"} />

              <NestBlockButton key={"nestBlockButton"} />
              <UnnestBlockButton key={"unnestBlockButton"} />

              <CreateLinkButton key={"createLinkButton"} />
            </FormattingToolbar>
          )}
        />

        <SuggestionMenuController
          triggerCharacter={"@"}
          getItems={async (query) => filterSuggestionItems(getMentionMenuItems(editor), query)}
        />

        <SuggestionMenuController
          triggerCharacter={"&%#"}
          getItems={async (query) => filterSuggestionItems(getAttributeMenuItems(editor), query)}
        />

        <SuggestionMenuController
          triggerCharacter={"/"}
          getItems={async (query) => filterSuggestionItems(getCustomSlashMenuItems(editor), query)}
        />
      </BlockNoteView>
    </div>
  );
};
