export const localeFa = {
  slash_menu: {
    heading: {
      title: "تیتر ۱",
      subtext: "",
      aliases: ["h", "heading1", "h1"],
      group: "تیترها",
    },
    heading_2: {
      title: "تیتر ۲",
      subtext: "",
      aliases: ["h2", "heading2", "subheading"],
      group: "تیترها",
    },
    heading_3: {
      title: "تیتر ۳",
      subtext: "",
      aliases: ["h3", "heading3", "subheading"],
      group: "تیترها",
    },
    numbered_list: {
      title: "لیست ترتیبی",
      subtext: "",
      aliases: ["ol", "li", "list", "numberedlist", "numbered list"],
      group: "بخش های پایه",
    },
    bullet_list: {
      title: "لیست",
      subtext: "",
      aliases: ["ul", "li", "list", "bulletlist", "bullet list"],
      group: "بخش های پایه",
    },
    check_list: {
      title: "چک لیست",
      subtext: "",
      aliases: [
        "ul",
        "li",
        "list",
        "checklist",
        "check list",
        "checked list",
        "checkbox",
      ],
      group: "بخش های پایه",
    },
    paragraph: {
      title: "پاراگراف",
      subtext: "",
      aliases: ["p", "paragraph"],
      group: "بخش های پایه",
    },
    code_block: {
      title: "بلاک کد",
      subtext: "",
      aliases: ["code"],
      group: "بخش های پایه",
    },
    page_break: {
      title: '',
      subtext: '',
      aliases: [],
      group: ''
    },
    table: {
      title: "جدول",
      subtext: "",
      aliases: ["table"],
      group: "پیشرفته",
    },
    image: {
      title: "تصویر",
      subtext: "",
      aliases: [
        "image",
        "imageUpload",
        "upload",
        "img",
        "picture",
        "media",
        "url",
      ],
      group: "مدیا",
    },
    video: {
      title: "ویدیو",
      subtext: "",
      aliases: [
        "video",
        "videoUpload",
        "upload",
        "mp4",
        "film",
        "media",
        "url",
      ],
      group: "مدیا",
    },
    audio: {
      title: "صوت",
      subtext: "",
      aliases: [
        "audio",
        "audioUpload",
        "upload",
        "mp3",
        "sound",
        "media",
        "url",
      ],
      group: "مدیا",
    },
    file: {
      title: "فایل",
      subtext: "",
      aliases: ["file", "upload", "embed", "media", "url"],
      group: "مدیا",
    },
    emoji: {
      title: "ایموجی",
      subtext: "",
      aliases: ["emoji", "emote", "emotion", "face"],
      group: "سایر",
    },
  },
  placeholders: {
    default: "بنویسید و یا با / دستورات را مشاهده کنید",
    heading: "تیتر",
    bulletListItem: "لیست",
    numberedListItem: "لیست",
    checkListItem: "لیست",
  },
  file_blocks: {
    image: {
      add_button_text: "اضافه کردن تصویر",
    },
    video: {
      add_button_text: "اضافه کردن ویدیو",
    },
    audio: {
      add_button_text: "اضافه کردن صوت",
    },
    file: {
      add_button_text: "اضافه کردن فایل",
    },
  },
  // from react package:
  side_menu: {
    add_block_label: "بخش جدید",
    drag_handle_label: "منوی دستورات",
  },
  drag_handle: {
    delete_menuitem: "حذف",
    colors_menuitem: "رنگ",
  },
  table_handle: {
    delete_column_menuitem: "حذف ستون",
    delete_row_menuitem: "حذف سطر",
    add_left_menuitem: "ستون جدید در چپ",
    add_right_menuitem: "ستون جدید در راست",
    add_above_menuitem: "سطر جدید در بالا",
    add_below_menuitem: "سطر جدید در پایین",
  },
  suggestion_menu: {
    no_items_title: "مقداری یافت نشد",
    loading: "در حال دریافت",
  },
  color_picker: {
    text_title: "متن",
    background_title: "پس‌زمینه",
    colors: {
      default: "پیش‌فرض",
      gray: "طوسی",
      brown: "قهوه‌ای",
      red: "قرمز",
      orange: "نارنجی",
      yellow: "زرد",
      green: "سبز",
      blue: "آبی",
      purple: "بنفش",
      pink: "صورتی",
    },
  },

  formatting_toolbar: {
    bold: {
      tooltip: "ضخیم",
      secondary_tooltip: "Mod+B",
    },
    italic: {
      tooltip: "کج",
      secondary_tooltip: "Mod+I",
    },
    underline: {
      tooltip: "زیرخط",
      secondary_tooltip: "Mod+U",
    },
    strike: {
      tooltip: "خط",
      secondary_tooltip: "Mod+Shift+S",
    },
    code: {
      tooltip: "کد",
      secondary_tooltip: "",
    },
    colors: {
      tooltip: "رنگ",
    },
    link: {
      tooltip: "ساخت لینک",
      secondary_tooltip: "Mod+K",
    },
    file_caption: {
      tooltip: "ویرایش عنوان",
      input_placeholder: "ویرایش عنوان",
    },
    file_replace: {
      tooltip: {
        image: "تغییر تصویر",
        video: "تغییر ویدیو",
        audio: "تغییر صوت",
        file: "تغییر فایل",
      } as Record<string, string>,
    },
    file_rename: {
      tooltip: {
        image: "تغییر نام",
        video: "تغییر نام",
        audio: "تغییر نام",
        file: "تغییر نام",
      } as Record<string, string>,
      input_placeholder: {
        image: "تغییر نام",
        video: "تغییر نام",
        audio: "تغییر نام",
        file: "تغییر نام",
      } as Record<string, string>,
    },
    file_download: {
      tooltip: {
        image: "دانلود تصویر",
        video: "دانلود ویدیو",
        audio: "دانلود صوت",
        file: "دانلود فایل",
      } as Record<string, string>,
    },
    file_delete: {
      tooltip: {
        image: "حذف تصویر",
        video: "حذف ویدیو",
        audio: "حذف صوت",
        file: "حذف فایل",
      } as Record<string, string>,
    },
    file_preview_toggle: {
      tooltip: "نمایش",
    },
    nest: {
      tooltip: "بخش داخلی",
      secondary_tooltip: "Tab",
    },
    unnest: {
      tooltip: "بازگردانی",
      secondary_tooltip: "Shift+Tab",
    },
    align_left: {
      tooltip: "چپ چین",
    },
    align_center: {
      tooltip: "وسط چین",
    },
    align_right: {
      tooltip: "راست چین",
    },
    align_justify: {
      tooltip: "کل خط",
    },
  },
  file_panel: {
    upload: {
      title: "بارگذاری",
      file_placeholder: {
        image: "بارگذاری",
        video: "بارگذاری",
        audio: "بارگذاری",
        file: "بارگذاری",
      } as Record<string, string>,
      upload_error: "خطا در بارگذاری",
    },
    embed: {
      title: "جایگذاری",
      embed_button: {
        image: "جایگذاری تصویر",
        video: "جایگذاری ویدیو",
        audio: "جایگذاری صوت",
        file: "جایگذاری فایل",
      } as Record<string, string>,
      url_placeholder: "لینک",
    },
  },
  link_toolbar: {
    delete: {
      tooltip: "حذف لینک",
    },
    edit: {
      text: "ویرایش لینک",
      tooltip: "ویرایش",
    },
    open: {
      tooltip: "تب جدید",
    },
    form: {
      title_placeholder: "ویرایش عنوان",
      url_placeholder: "ویرایش لینک",
    },
  },
  generic: {
    ctrl_shortcut: "Ctrl",
  },

  multi_column:{
    slash_menu: {
      two_columns: {
        title: "دو ستون",
        subtext: "دو ستون کنار هم",
        aliases: ["columns", "row", "split"],
        group: "پیشرفته",
      },
      three_columns: {
        title: "سه ستون",
        subtext: "سه ستون کنار هم",
        aliases: ["columns", "row", "split"],
        group: "پیشرفته",
      },
    },
  },
  ask_ai:{
    slash_menu:{
      title: "Ask AI",
      subtext: "Ask AI",
      aliases: ["ai"],
      group: "Others",
    }
  }
};
