import { jsx as i, jsxs as S } from "react/jsx-runtime";
import { assertEmpty as d, isSafari as M, COLORS_DEFAULT as q, COLORS_DARK_MODE_DEFAULT as Z, mergeCSSClasses as J } from "@blocknote/core";
import { elementOverflow as z, useBlockNoteContext as Q, usePrefersColorScheme as X, ComponentsContext as Y, BlockNoteViewRaw as ee } from "@blocknote/react";
import { TextInput as B, Menu as b, CheckIcon as _, Group as p, Tabs as w, LoadingOverlay as te, Button as x, FileInput as ne, Popover as oe, PopoverTarget as re, PopoverDropdown as se, ActionIcon as $, Loader as V, Stack as O, Text as N, Badge as ie, Tooltip as ae, MantineProvider as le } from "@mantine/core";
import y, { forwardRef as g, createContext as ce, useState as ue, useRef as C, useCallback as F, useContext as de, useEffect as A } from "react";
import { mergeRefs as T, useFocusWithin as me, useFocusTrap as ge } from "@mantine/hooks";
const G = (e, n, t = !1) => {
    const o = [];
    function r(s, l = "--bn") {
      for (const a in s) {
        const c = a.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase(),
          u = `${l}-${c}`;
        typeof s[a] != "object" ? (typeof s[a] == "number" && (s[a] = `${s[a]}px`), t ? n.style.removeProperty(u) : n.style.setProperty(u, s[a].toString())) : r(s[a], u);
      }
    }
    return r(e), o;
  },
  D = (e, n) => G(e, n),
  fe = {
    colors: {
      editor: {
        text: void 0,
        background: void 0
      },
      menu: {
        text: void 0,
        background: void 0
      },
      tooltip: {
        text: void 0,
        background: void 0
      },
      hovered: {
        text: void 0,
        background: void 0
      },
      selected: {
        text: void 0,
        background: void 0
      },
      disabled: {
        text: void 0,
        background: void 0
      },
      shadow: void 0,
      border: void 0,
      sideMenu: void 0,
      highlights: {
        gray: {
          text: void 0,
          background: void 0
        },
        brown: {
          text: void 0,
          background: void 0
        },
        red: {
          text: void 0,
          background: void 0
        },
        orange: {
          text: void 0,
          background: void 0
        },
        yellow: {
          text: void 0,
          background: void 0
        },
        green: {
          text: void 0,
          background: void 0
        },
        blue: {
          text: void 0,
          background: void 0
        },
        purple: {
          text: void 0,
          background: void 0
        },
        pink: {
          text: void 0,
          background: void 0
        }
      }
    },
    borderRadius: void 0,
    fontFamily: void 0
  },
  be = e => G(fe, e, !0),
  pe = g((e, n) => {
    const {
      className: t,
      name: o,
      label: r,
      icon: s,
      value: l,
      autoFocus: a,
      placeholder: c,
      onKeyDown: u,
      onChange: f,
      onSubmit: v,
      ...h
    } = e;
    return d(h), /* @__PURE__ */i(B, {
      size: "xs",
      className: t,
      ref: n,
      name: o,
      label: r,
      leftSection: s,
      value: l,
      autoFocus: a,
      "data-autofocus": a ? "true" : void 0,
      placeholder: c,
      onKeyDown: u,
      onChange: f,
      onSubmit: v
    });
  });
var H = {
    color: void 0,
    size: void 0,
    className: void 0,
    style: void 0,
    attr: void 0
  },
  R = y.createContext && /* @__PURE__ */y.createContext(H),
  ve = ["attr", "size", "title"];
function he(e, n) {
  if (e == null) return {};
  var t = ye(e, n),
    o,
    r;
  if (Object.getOwnPropertySymbols) {
    var s = Object.getOwnPropertySymbols(e);
    for (r = 0; r < s.length; r++) o = s[r], !(n.indexOf(o) >= 0) && Object.prototype.propertyIsEnumerable.call(e, o) && (t[o] = e[o]);
  }
  return t;
}
function ye(e, n) {
  if (e == null) return {};
  var t = {};
  for (var o in e) if (Object.prototype.hasOwnProperty.call(e, o)) {
    if (n.indexOf(o) >= 0) continue;
    t[o] = e[o];
  }
  return t;
}
function k() {
  return k = Object.assign ? Object.assign.bind() : function (e) {
    for (var n = 1; n < arguments.length; n++) {
      var t = arguments[n];
      for (var o in t) Object.prototype.hasOwnProperty.call(t, o) && (e[o] = t[o]);
    }
    return e;
  }, k.apply(this, arguments);
}
function I(e, n) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    n && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function P(e) {
  for (var n = 1; n < arguments.length; n++) {
    var t = arguments[n] != null ? arguments[n] : {};
    n % 2 ? I(Object(t), !0).forEach(function (o) {
      Se(e, o, t[o]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : I(Object(t)).forEach(function (o) {
      Object.defineProperty(e, o, Object.getOwnPropertyDescriptor(t, o));
    });
  }
  return e;
}
function Se(e, n, t) {
  return n = xe(n), n in e ? Object.defineProperty(e, n, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[n] = t, e;
}
function xe(e) {
  var n = we(e, "string");
  return typeof n == "symbol" ? n : n + "";
}
function we(e, n) {
  if (typeof e != "object" || !e) return e;
  var t = e[Symbol.toPrimitive];
  if (t !== void 0) {
    var o = t.call(e, n || "default");
    if (typeof o != "object") return o;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (n === "string" ? String : Number)(e);
}
function K(e) {
  return e && e.map((n, t) => /* @__PURE__ */y.createElement(n.tag, P({
    key: t
  }, n.attr), K(n.child)));
}
function U(e) {
  return n => /* @__PURE__ */y.createElement(Ne, k({
    attr: P({}, e.attr)
  }, n), K(e.child));
}
function Ne(e) {
  var n = t => {
    var {
        attr: o,
        size: r,
        title: s
      } = e,
      l = he(e, ve),
      a = r || t.size || "1em",
      c;
    return t.className && (c = t.className), e.className && (c = (c ? c + " " : "") + e.className), /* @__PURE__ */y.createElement("svg", k({
      stroke: "currentColor",
      fill: "currentColor",
      strokeWidth: "0"
    }, t.attr, o, l, {
      className: c,
      style: P(P({
        color: e.color || t.color
      }, t.style), e.style),
      height: a,
      width: a,
      xmlns: "http://www.w3.org/2000/svg"
    }), s && /* @__PURE__ */y.createElement("title", null, s), e.children);
  };
  return R !== void 0 ? /* @__PURE__ */y.createElement(R.Consumer, null, t => n(t)) : n(H);
}
function Ce(e) {
  return U({
    tag: "svg",
    attr: {
      viewBox: "0 0 20 20",
      fill: "currentColor",
      "aria-hidden": "true"
    },
    child: [{
      tag: "path",
      attr: {
        fillRule: "evenodd",
        d: "M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z",
        clipRule: "evenodd"
      },
      child: []
    }]
  })(e);
}
function ke(e) {
  return U({
    tag: "svg",
    attr: {
      viewBox: "0 0 20 20",
      fill: "currentColor",
      "aria-hidden": "true"
    },
    child: [{
      tag: "path",
      attr: {
        fillRule: "evenodd",
        d: "M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z",
        clipRule: "evenodd"
      },
      child: []
    }]
  })(e);
}
const W = ce(void 0),
  Pe = g((e, n) => {
    const {
      children: t,
      onOpenChange: o,
      position: r,
      sub: s,
      // not used
      ...l
    } = e;
    d(l);
    const [a, c] = ue(!1),
      u = C(null),
      f = C(),
      v = F(() => {
        f.current && clearTimeout(f.current), f.current = setTimeout(() => {
          c(!1);
        }, 250);
      }, []),
      h = F(() => {
        f.current && clearTimeout(f.current), c(!0);
      }, []);
    return /* @__PURE__ */i(W.Provider, {
      value: {
        onMenuMouseOver: h,
        onMenuMouseLeave: v
      },
      children: /* @__PURE__ */i(b.Item, {
        className: "bn-menu-item bn-mt-sub-menu-item",
        ref: T(n, u),
        onMouseOver: h,
        onMouseLeave: v,
        children: /* @__PURE__ */i(b, {
          portalProps: {
            target: u.current ? u.current.parentElement : void 0
          },
          middlewares: {
            flip: !0,
            shift: !0,
            inline: !1,
            size: !0
          },
          trigger: "hover",
          opened: a,
          onClose: () => o == null ? void 0 : o(!1),
          onOpen: () => o == null ? void 0 : o(!0),
          position: r,
          children: t
        })
      })
    });
  }),
  Te = e => {
    const {
      children: n,
      onOpenChange: t,
      position: o,
      sub: r,
      ...s
    } = e;
    return d(s), r ? /* @__PURE__ */i(Pe, {
      ...e
    }) : /* @__PURE__ */i(b, {
      withinPortal: !1,
      middlewares: {
        flip: !0,
        shift: !0,
        inline: !1,
        size: !0
      },
      onClose: () => t == null ? void 0 : t(!1),
      onOpen: () => t == null ? void 0 : t(!0),
      position: o,
      children: n
    });
  },
  Me = g((e, n) => {
    const {
      className: t,
      children: o,
      icon: r,
      checked: s,
      subTrigger: l,
      onClick: a,
      ...c
    } = e;
    return d(c, !1), l ? /* @__PURE__ */S("div", {
      onClick: u => {
        u.preventDefault(), u.stopPropagation();
      },
      ref: n,
      ...c,
      children: [o, /* @__PURE__ */i(ke, {
        size: 15
      })]
    }) : /* @__PURE__ */i(b.Item, {
      className: t,
      ref: n,
      leftSection: r,
      rightSection: s ? /* @__PURE__ */i(_, {
        size: 10
      }) : s === !1 ? /* @__PURE__ */i("div", {
        className: "bn-tick-space"
      }) : null,
      onClick: a,
      ...c,
      children: o
    });
  }),
  Fe = e => {
    const {
      children: n,
      sub: t,
      // unused
      ...o
    } = e;
    return d(o), /* @__PURE__ */i(b.Target, {
      children: n
    });
  },
  Oe = g((e, n) => {
    const {
      className: t,
      children: o,
      sub: r,
      //unused
      ...s
    } = e;
    d(s);
    const l = de(W);
    return /* @__PURE__ */i(b.Dropdown, {
      className: t,
      ref: n,
      onMouseOver: l == null ? void 0 : l.onMenuMouseOver,
      onMouseLeave: l == null ? void 0 : l.onMenuMouseLeave,
      children: o
    });
  }),
  De = g((e, n) => {
    const {
      className: t,
      ...o
    } = e;
    return d(o), /* @__PURE__ */i(b.Divider, {
      className: t,
      ref: n
    });
  }),
  Re = g((e, n) => {
    const {
      className: t,
      children: o,
      ...r
    } = e;
    return d(r), /* @__PURE__ */i(b.Label, {
      className: t,
      ref: n,
      children: o
    });
  }),
  Ie = g((e, n) => {
    const {
      className: t,
      tabs: o,
      defaultOpenTab: r,
      openTab: s,
      setOpenTab: l,
      loading: a,
      ...c
    } = e;
    return d(c), /* @__PURE__ */i(p, {
      className: t,
      ref: n,
      children: /* @__PURE__ */S(w, {
        value: s,
        defaultValue: r,
        onChange: l,
        children: [a && /* @__PURE__ */i(te, {
          visible: a
        }), /* @__PURE__ */i(w.List, {
          children: o.map(u => /* @__PURE__ */i(w.Tab, {
            "data-test": `${u.name.toLowerCase()}-tab`,
            value: u.name,
            children: u.name
          }, u.name))
        }), o.map(u => /* @__PURE__ */i(w.Panel, {
          value: u.name,
          children: u.tabPanel
        }, u.name))]
      })
    });
  }),
  Le = g((e, n) => {
    const {
      className: t,
      children: o,
      onClick: r,
      label: s,
      ...l
    } = e;
    return d(l), /* @__PURE__ */i(x, {
      size: "xs",
      "aria-label": s,
      className: t,
      ref: n,
      onClick: r,
      ...l,
      children: o
    });
  }),
  Ee = g((e, n) => {
    const {
      className: t,
      accept: o,
      value: r,
      placeholder: s,
      onChange: l,
      ...a
    } = e;
    return d(a), /* @__PURE__ */i(ne, {
      size: "xs",
      className: t,
      ref: n,
      accept: o,
      value: r,
      placeholder: s,
      onChange: l,
      ...a
    });
  }),
  je = g((e, n) => {
    const {
      className: t,
      children: o,
      ...r
    } = e;
    return d(r), /* @__PURE__ */i("div", {
      className: t,
      ref: n,
      children: o
    });
  }),
  ze = g((e, n) => {
    const {
      className: t,
      value: o,
      placeholder: r,
      onKeyDown: s,
      onChange: l,
      ...a
    } = e;
    return d(a), /* @__PURE__ */i(B, {
      size: "xs",
      "data-test": "embed-input",
      className: t,
      ref: n,
      value: o,
      placeholder: r,
      onKeyDown: s,
      onChange: l
    });
  }),
  Be = e => {
    const {
      children: n,
      opened: t,
      position: o,
      ...r
    } = e;
    return d(r), /* @__PURE__ */i(oe, {
      withinPortal: !1,
      opened: t,
      position: o,
      children: n
    });
  },
  _e = e => {
    const {
      children: n,
      ...t
    } = e;
    return d(t), /* @__PURE__ */i(re, {
      children: n
    });
  },
  $e = g((e, n) => {
    const {
      className: t,
      children: o,
      variant: r,
      // unused
      ...s
    } = e;
    return d(s), /* @__PURE__ */i(se, {
      className: t,
      ref: n,
      children: o
    });
  }),
  Ve = g((e, n) => {
    const {
      className: t,
      children: o,
      ...r
    } = e;
    return d(r, !1), /* @__PURE__ */i(p, {
      align: "center",
      gap: 0,
      className: t,
      ref: n,
      ...r,
      children: o
    });
  }),
  Ae = g((e, n) => {
    const {
      className: t,
      children: o,
      icon: r,
      onClick: s,
      onDragEnd: l,
      onDragStart: a,
      draggable: c,
      label: u,
      ...f
    } = e;
    return d(f, !1), r ? /* @__PURE__ */i($, {
      size: 24,
      className: t,
      ref: n,
      onClick: s,
      onDragEnd: l,
      onDragStart: a,
      draggable: c,
      "aria-label": u,
      ...f,
      children: r
    }) : /* @__PURE__ */i(x, {
      className: t,
      ref: n,
      onClick: s,
      onDragEnd: l,
      onDragStart: a,
      draggable: c,
      "aria-label": u,
      ...f,
      children: o
    });
  }),
  Ge = g((e, n) => {
    const {
      className: t,
      children: o,
      id: r,
      columns: s,
      ...l
    } = e;
    return d(l), /* @__PURE__ */i("div", {
      className: t,
      style: {
        gridTemplateColumns: `repeat(${s}, 1fr)`
      },
      ref: n,
      id: r,
      role: "grid",
      children: o
    });
  }),
  He = g((e, n) => {
    const {
      className: t,
      children: o,
      columns: r,
      ...s
    } = e;
    return d(s), /* @__PURE__ */i(p, {
      className: t,
      style: {
        gridColumn: `1 / ${r + 1}`
      },
      ref: n,
      children: /* @__PURE__ */i(p, {
        className: "bn-mt-suggestion-menu-item-title",
        children: o
      })
    });
  }),
  Ke = g((e, n) => {
    const {
      className: t,
      isSelected: o,
      onClick: r,
      item: s,
      id: l,
      ...a
    } = e;
    d(a);
    const c = C(null);
    return A(() => {
      if (!c.current || !o) return;
      const u = z(c.current, document.querySelector(".bn-grid-suggestion-menu"));
      u === "top" ? c.current.scrollIntoView(!0) : u === "bottom" && c.current.scrollIntoView(!1);
    }, [o]), /* @__PURE__ */i("div", {
      className: t,
      ref: T(n, c),
      id: l,
      role: "option",
      onClick: r,
      "aria-selected": o || void 0,
      children: s.icon
    });
  }),
  Ue = g((e, n) => {
    const {
      className: t,
      children: o,
      // unused, using "dots" instead
      columns: r,
      ...s
    } = e;
    return d(s), /* @__PURE__ */i(V, {
      className: t,
      style: {
        gridColumn: `1 / ${r + 1}`
      },
      type: "dots",
      ref: n
    });
  }),
  We = g((e, n) => {
    const {
      className: t,
      children: o,
      id: r,
      ...s
    } = e;
    return d(s), /* @__PURE__ */i(O, {
      gap: 0,
      className: t,
      ref: n,
      id: r,
      role: "listbox",
      children: o
    });
  }),
  qe = g((e, n) => {
    const {
      className: t,
      children: o,
      ...r
    } = e;
    return d(r), /* @__PURE__ */i(p, {
      className: t,
      ref: n,
      children: /* @__PURE__ */i(p, {
        className: "bn-mt-suggestion-menu-item-title",
        children: o
      })
    });
  }),
  Ze = g((e, n) => {
    const {
      className: t,
      isSelected: o,
      onClick: r,
      item: s,
      id: l,
      ...a
    } = e;
    d(a);
    const c = C(null);
    return A(() => {
      if (!c.current || !o) return;
      const u = z(c.current, document.querySelector(".bn-suggestion-menu"));
      u === "top" ? c.current.scrollIntoView(!0) : u === "bottom" && c.current.scrollIntoView(!1);
    }, [o]), /* @__PURE__ */S(p, {
      gap: 0,
      className: t,
      ref: T(n, c),
      id: l,
      role: "option",
      onClick: r,
      "aria-selected": o || void 0,
      children: [s.icon && /* @__PURE__ */i(p, {
        className: "bn-mt-suggestion-menu-item-section",
        "data-position": "left",
        children: s.icon
      }), /* @__PURE__ */S(O, {
        gap: 0,
        className: "bn-mt-suggestion-menu-item-body",
        children: [/* @__PURE__ */i(N, {
          className: "bn-mt-suggestion-menu-item-title",
          children: s.title
        }), /* @__PURE__ */i(N, {
          className: "bn-mt-suggestion-menu-item-subtitle",
          children: s.subtext
        })]
      }), s.badge && /* @__PURE__ */i(p, {
        "data-position": "right",
        className: "bn-mt-suggestion-menu-item-section",
        children: /* @__PURE__ */i(ie, {
          size: "xs",
          children: s.badge
        })
      })]
    });
  }),
  Je = g((e, n) => {
    const {
      className: t,
      children: o,
      ...r
    } = e;
    return d(r), /* @__PURE__ */i(p, {
      className: t,
      ref: n,
      children: o
    });
  }),
  Qe = g((e, n) => {
    const {
      className: t,
      children: o,
      // unused, using "dots" instead
      ...r
    } = e;
    return d(r), /* @__PURE__ */i(V, {
      className: t,
      type: "dots",
      ref: n
    });
  }),
  Xe = g((e, n) => {
    const {
      children: t,
      className: o,
      onMouseDown: r,
      onClick: s,
      ...l
    } = e;
    return d(l, !1), /* @__PURE__ */i(x, {
      className: o,
      ref: n,
      onMouseDown: r,
      onClick: s,
      ...l,
      children: t
    });
  }),
  Ye = g((e, n) => {
    const {
      className: t,
      children: o,
      draggable: r,
      onDragStart: s,
      onDragEnd: l,
      style: a,
      label: c,
      ...u
    } = e;
    return d(u, !1), /* @__PURE__ */i(x, {
      className: t,
      ref: n,
      "aria-label": c,
      draggable: r,
      onDragStart: s,
      onDragEnd: l,
      style: a,
      ...u,
      children: o
    });
  }),
  L = g((e, n) => {
    const {
      className: t,
      children: o,
      onMouseEnter: r,
      onMouseLeave: s,
      ...l
    } = e;
    d(l);
    const {
        ref: a,
        focused: c
      } = me(),
      u = ge(c),
      f = T(n, a, u);
    return /* @__PURE__ */i(p, {
      className: t,
      ref: f,
      role: "toolbar",
      onMouseEnter: r,
      onMouseLeave: s,
      children: o
    });
  }),
  et = e => /* @__PURE__ */S(O, {
    gap: 0,
    className: "bn-tooltip",
    children: [/* @__PURE__ */i(N, {
      size: "sm",
      children: e.mainTooltip
    }), e.secondaryTooltip && /* @__PURE__ */i(N, {
      size: "xs",
      children: e.secondaryTooltip
    })]
  }),
  E = g((e, n) => {
    const {
      className: t,
      children: o,
      mainTooltip: r,
      secondaryTooltip: s,
      icon: l,
      isSelected: a,
      isDisabled: c,
      onClick: u,
      label: f,
      ...v
    } = e;
    return d(v, !1), /* @__PURE__ */i(ae, {
      withinPortal: !1,
      label: /* @__PURE__ */i(et, {
        mainTooltip: r,
        secondaryTooltip: s
      }),
      children: o ? /* @__PURE__ */i(x, {
        "aria-label": f,
        className: t,
        onMouseDown: h => {
          M() && h.currentTarget.focus();
        },
        onClick: u,
        "aria-pressed": a,
        "data-selected": a || void 0,
        "data-test": r.slice(0, 1).toLowerCase() + r.replace(/\s+/g, "").slice(1),
        size: "xs",
        disabled: c || !1,
        ref: n,
        ...v,
        children: o
      }) : /* @__PURE__ */i($, {
        className: t,
        "aria-label": f,
        onMouseDown: h => {
          M() && h.currentTarget.focus();
        },
        onClick: u,
        "aria-pressed": a,
        "data-selected": a || void 0,
        "data-test": r.slice(0, 1).toLowerCase() + r.replace(/\s+/g, "").slice(1),
        size: 30,
        disabled: c || !1,
        ref: n,
        ...v,
        children: l
      })
    });
  }),
  tt = g((e, n) => {
    const {
      className: t,
      items: o,
      isDisabled: r,
      ...s
    } = e;
    d(s);
    const l = o.filter(a => a.isSelected)[0];
    return l ? /* @__PURE__ */S(b, {
      withinPortal: !1,
      transitionProps: {
        exitDuration: 0
      },
      disabled: r,
      middlewares: {
        flip: !0,
        shift: !0,
        inline: !1,
        size: !0
      },
      children: [/* @__PURE__ */i(b.Target, {
        children: /* @__PURE__ */i(x, {
          onMouseDown: a => {
            M() && a.currentTarget.focus();
          },
          leftSection: l.icon,
          rightSection: /* @__PURE__ */i(Ce, {}),
          size: "xs",
          variant: "subtle",
          disabled: r,
          children: l.text
        })
      }), /* @__PURE__ */i(b.Dropdown, {
        className: t,
        ref: n,
        children: o.map(a => /* @__PURE__ */i(b.Item, {
          onClick: a.onClick,
          leftSection: a.icon,
          rightSection: a.isSelected ? /* @__PURE__ */i(_, {
            size: 10,
            className: "bn-tick-icon"
          }) :
          // Ensures space for tick even if item isn't currently selected.
          /* @__PURE__ */
          i("div", {
            className: "bn-tick-space"
          }),
          disabled: a.isDisabled,
          children: a.text
        }, a.text))
      })]
    }) : null;
  }),
  m = ["#FFFFFF", "#EFEFEF", "#CFCFCF", "#AFAFAF", "#7F7F7F", "#3F3F3F", "#1F1F1F", "#161616", "#0F0F0F", "#000000"],
  j = {
    colors: {
      editor: {
        text: m[5],
        background: m[0]
      },
      menu: {
        text: m[5],
        background: m[0]
      },
      tooltip: {
        text: m[5],
        background: m[1]
      },
      hovered: {
        text: m[5],
        background: m[1]
      },
      selected: {
        text: m[0],
        background: m[5]
      },
      disabled: {
        text: m[3],
        background: m[1]
      },
      shadow: m[2],
      border: m[1],
      sideMenu: m[2],
      highlights: q
    },
    borderRadius: 6,
    fontFamily: '"Inter", "SF Pro Display", -apple-system, BlinkMacSystemFont, "Open Sans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
  },
  ut = {
    colors: {
      editor: {
        text: m[2],
        background: m[6]
      },
      menu: {
        text: m[2],
        background: m[6]
      },
      tooltip: {
        text: m[2],
        background: m[7]
      },
      hovered: {
        text: m[2],
        background: m[7]
      },
      selected: {
        text: m[2],
        background: m[8]
      },
      disabled: {
        text: m[5],
        background: m[7]
      },
      shadow: m[8],
      border: m[7],
      sideMenu: m[4],
      highlights: Z
    },
    borderRadius: j.borderRadius,
    fontFamily: j.fontFamily
  },
  nt = {
    FormattingToolbar: {
      Root: L,
      Button: E,
      Select: tt
    },
    FilePanel: {
      Root: Ie,
      Button: Le,
      FileInput: Ee,
      TabPanel: je,
      TextInput: ze
    },
    GridSuggestionMenu: {
      Root: Ge,
      Item: Ke,
      EmptyItem: He,
      Loader: Ue
    },
    LinkToolbar: {
      Root: L,
      Button: E
    },
    SideMenu: {
      Root: Ve,
      Button: Ae
    },
    SuggestionMenu: {
      Root: We,
      Item: Ze,
      EmptyItem: qe,
      Label: Je,
      Loader: Qe
    },
    TableHandle: {
      Root: Ye,
      ExtendButton: Xe
    },
    Generic: {
      Form: {
        Root: e => /* @__PURE__ */i("div", {
          children: e.children
        }),
        TextInput: pe
      },
      Menu: {
        Root: Te,
        Trigger: Fe,
        Dropdown: Oe,
        Divider: De,
        Label: Re,
        Item: Me
      },
      Popover: {
        Root: Be,
        Trigger: _e,
        Content: $e
      }
    }
  },
  ot = {
    // Removes button press effect
    activeClassName: ""
  },
  dt = e => {
    const {
        className: n,
        theme: t,
        ...o
      } = e,
      r = Q(),
      s = X(),
      l = (r == null ? void 0 : r.colorSchemePreference) || s,
      a = F(c => {
        if (c && (be(c), typeof t == "object")) {
          if ("light" in t && "dark" in t) {
            D(t[l === "dark" ? "dark" : "light"], c);
            return;
          }
          D(t, c);
          return;
        }
      }, [l, t]);
    return /* @__PURE__ */i(Y.Provider, {
      value: nt,
      children: /* @__PURE__ */i(le, {
        theme: ot,
        cssVariablesSelector: ".bn-mantine",
        getRootElement: () => {},
        children: /* @__PURE__ */i(ee, {
          className: J("bn-mantine", n || ""),
          theme: typeof t == "object" ? void 0 : t,
          ...o,
          ref: a
        })
      })
    });
  };
export { dt as BlockNoteView, D as applyBlockNoteCSSVariablesFromTheme, nt as components, ut as darkDefaultTheme, m as defaultColorScheme, j as lightDefaultTheme, be as removeBlockNoteCSSVariables };
