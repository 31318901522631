import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from "@sentry/angular";
import { version } from '../package.json';
import {CustomBlockNoteEditor} from "./app/editor/page-react/blocknote.react";
import reactToWebComponent from "react-to-webcomponent";
import React from 'react';
import ReactDOM from 'react-dom/client';
import {createCustomElement} from "@angular/elements";
import {AttributeComponent} from "./app/attributes/attribute/attribute.component";

Sentry.init({
  dsn: "https://9e9292cc5e391d94f12576111313c72f@o4507539380895744.ingest.de.sentry.io/4507539384893520",
  release: "atwork-panel@" + version,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.2,
  tracePropagationTargets: ["localhost", /^https:\/\/*.atwork\.io/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

const BlockNoteWebComponent = reactToWebComponent(CustomBlockNoteEditor, React, ReactDOM);

customElements.define('blocknote-editor', BlockNoteWebComponent);
// createCustomElement(AttributeComponent,{injector:});
