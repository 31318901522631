const bundledThemesInfo = [{
  "id": "andromeeda",
  "displayName": "Andromeeda",
  "type": "dark",
  "import": () => import('./themes/andromeeda.mjs')
}, {
  "id": "aurora-x",
  "displayName": "Aurora X",
  "type": "dark",
  "import": () => import('./themes/aurora-x.mjs')
}, {
  "id": "ayu-dark",
  "displayName": "Ayu Dark",
  "type": "dark",
  "import": () => import('./themes/ayu-dark.mjs')
}, {
  "id": "catppuccin-frappe",
  "displayName": "Catppuccin Frapp\xE9",
  "type": "dark",
  "import": () => import('./themes/catppuccin-frappe.mjs')
}, {
  "id": "catppuccin-latte",
  "displayName": "Catppuccin Latte",
  "type": "light",
  "import": () => import('./themes/catppuccin-latte.mjs')
}, {
  "id": "catppuccin-macchiato",
  "displayName": "Catppuccin Macchiato",
  "type": "dark",
  "import": () => import('./themes/catppuccin-macchiato.mjs')
}, {
  "id": "catppuccin-mocha",
  "displayName": "Catppuccin Mocha",
  "type": "dark",
  "import": () => import('./themes/catppuccin-mocha.mjs')
}, {
  "id": "dark-plus",
  "displayName": "Dark Plus",
  "type": "dark",
  "import": () => import('./themes/dark-plus.mjs')
}, {
  "id": "dracula",
  "displayName": "Dracula Theme",
  "type": "dark",
  "import": () => import('./themes/dracula.mjs')
}, {
  "id": "dracula-soft",
  "displayName": "Dracula Theme Soft",
  "type": "dark",
  "import": () => import('./themes/dracula-soft.mjs')
}, {
  "id": "everforest-dark",
  "displayName": "Everforest Dark",
  "type": "dark",
  "import": () => import('./themes/everforest-dark.mjs')
}, {
  "id": "everforest-light",
  "displayName": "Everforest Light",
  "type": "light",
  "import": () => import('./themes/everforest-light.mjs')
}, {
  "id": "github-dark",
  "displayName": "GitHub Dark",
  "type": "dark",
  "import": () => import('./themes/github-dark.mjs')
}, {
  "id": "github-dark-default",
  "displayName": "GitHub Dark Default",
  "type": "dark",
  "import": () => import('./themes/github-dark-default.mjs')
}, {
  "id": "github-dark-dimmed",
  "displayName": "GitHub Dark Dimmed",
  "type": "dark",
  "import": () => import('./themes/github-dark-dimmed.mjs')
}, {
  "id": "github-dark-high-contrast",
  "displayName": "GitHub Dark High Contrast",
  "type": "dark",
  "import": () => import('./themes/github-dark-high-contrast.mjs')
}, {
  "id": "github-light",
  "displayName": "GitHub Light",
  "type": "light",
  "import": () => import('./themes/github-light.mjs')
}, {
  "id": "github-light-default",
  "displayName": "GitHub Light Default",
  "type": "light",
  "import": () => import('./themes/github-light-default.mjs')
}, {
  "id": "github-light-high-contrast",
  "displayName": "GitHub Light High Contrast",
  "type": "light",
  "import": () => import('./themes/github-light-high-contrast.mjs')
}, {
  "id": "houston",
  "displayName": "Houston",
  "type": "dark",
  "import": () => import('./themes/houston.mjs')
}, {
  "id": "kanagawa-dragon",
  "displayName": "Kanagawa Dragon",
  "type": "dark",
  "import": () => import('./themes/kanagawa-dragon.mjs')
}, {
  "id": "kanagawa-lotus",
  "displayName": "Kanagawa Lotus",
  "type": "light",
  "import": () => import('./themes/kanagawa-lotus.mjs')
}, {
  "id": "kanagawa-wave",
  "displayName": "Kanagawa Wave",
  "type": "dark",
  "import": () => import('./themes/kanagawa-wave.mjs')
}, {
  "id": "laserwave",
  "displayName": "LaserWave",
  "type": "dark",
  "import": () => import('./themes/laserwave.mjs')
}, {
  "id": "light-plus",
  "displayName": "Light Plus",
  "type": "light",
  "import": () => import('./themes/light-plus.mjs')
}, {
  "id": "material-theme",
  "displayName": "Material Theme",
  "type": "dark",
  "import": () => import('./themes/material-theme.mjs')
}, {
  "id": "material-theme-darker",
  "displayName": "Material Theme Darker",
  "type": "dark",
  "import": () => import('./themes/material-theme-darker.mjs')
}, {
  "id": "material-theme-lighter",
  "displayName": "Material Theme Lighter",
  "type": "light",
  "import": () => import('./themes/material-theme-lighter.mjs')
}, {
  "id": "material-theme-ocean",
  "displayName": "Material Theme Ocean",
  "type": "dark",
  "import": () => import('./themes/material-theme-ocean.mjs')
}, {
  "id": "material-theme-palenight",
  "displayName": "Material Theme Palenight",
  "type": "dark",
  "import": () => import('./themes/material-theme-palenight.mjs')
}, {
  "id": "min-dark",
  "displayName": "Min Dark",
  "type": "dark",
  "import": () => import('./themes/min-dark.mjs')
}, {
  "id": "min-light",
  "displayName": "Min Light",
  "type": "light",
  "import": () => import('./themes/min-light.mjs')
}, {
  "id": "monokai",
  "displayName": "Monokai",
  "type": "dark",
  "import": () => import('./themes/monokai.mjs')
}, {
  "id": "night-owl",
  "displayName": "Night Owl",
  "type": "dark",
  "import": () => import('./themes/night-owl.mjs')
}, {
  "id": "nord",
  "displayName": "Nord",
  "type": "dark",
  "import": () => import('./themes/nord.mjs')
}, {
  "id": "one-dark-pro",
  "displayName": "One Dark Pro",
  "type": "dark",
  "import": () => import('./themes/one-dark-pro.mjs')
}, {
  "id": "one-light",
  "displayName": "One Light",
  "type": "light",
  "import": () => import('./themes/one-light.mjs')
}, {
  "id": "plastic",
  "displayName": "Plastic",
  "type": "dark",
  "import": () => import('./themes/plastic.mjs')
}, {
  "id": "poimandres",
  "displayName": "Poimandres",
  "type": "dark",
  "import": () => import('./themes/poimandres.mjs')
}, {
  "id": "red",
  "displayName": "Red",
  "type": "dark",
  "import": () => import('./themes/red.mjs')
}, {
  "id": "rose-pine",
  "displayName": "Ros\xE9 Pine",
  "type": "dark",
  "import": () => import('./themes/rose-pine.mjs')
}, {
  "id": "rose-pine-dawn",
  "displayName": "Ros\xE9 Pine Dawn",
  "type": "light",
  "import": () => import('./themes/rose-pine-dawn.mjs')
}, {
  "id": "rose-pine-moon",
  "displayName": "Ros\xE9 Pine Moon",
  "type": "dark",
  "import": () => import('./themes/rose-pine-moon.mjs')
}, {
  "id": "slack-dark",
  "displayName": "Slack Dark",
  "type": "dark",
  "import": () => import('./themes/slack-dark.mjs')
}, {
  "id": "slack-ochin",
  "displayName": "Slack Ochin",
  "type": "light",
  "import": () => import('./themes/slack-ochin.mjs')
}, {
  "id": "snazzy-light",
  "displayName": "Snazzy Light",
  "type": "light",
  "import": () => import('./themes/snazzy-light.mjs')
}, {
  "id": "solarized-dark",
  "displayName": "Solarized Dark",
  "type": "dark",
  "import": () => import('./themes/solarized-dark.mjs')
}, {
  "id": "solarized-light",
  "displayName": "Solarized Light",
  "type": "light",
  "import": () => import('./themes/solarized-light.mjs')
}, {
  "id": "synthwave-84",
  "displayName": "Synthwave '84",
  "type": "dark",
  "import": () => import('./themes/synthwave-84.mjs')
}, {
  "id": "tokyo-night",
  "displayName": "Tokyo Night",
  "type": "dark",
  "import": () => import('./themes/tokyo-night.mjs')
}, {
  "id": "vesper",
  "displayName": "Vesper",
  "type": "dark",
  "import": () => import('./themes/vesper.mjs')
}, {
  "id": "vitesse-black",
  "displayName": "Vitesse Black",
  "type": "dark",
  "import": () => import('./themes/vitesse-black.mjs')
}, {
  "id": "vitesse-dark",
  "displayName": "Vitesse Dark",
  "type": "dark",
  "import": () => import('./themes/vitesse-dark.mjs')
}, {
  "id": "vitesse-light",
  "displayName": "Vitesse Light",
  "type": "light",
  "import": () => import('./themes/vitesse-light.mjs')
}];
const bundledThemes = Object.fromEntries(bundledThemesInfo.map(i => [i.id, i.import]));
export { bundledThemes, bundledThemesInfo };