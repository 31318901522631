import { create } from './util/create.js';
export const xml = create({
  space: 'xml',
  transform(_, prop) {
    return 'xml:' + prop.slice(3).toLowerCase();
  },
  properties: {
    xmlLang: null,
    xmlBase: null,
    xmlSpace: null
  }
});