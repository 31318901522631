import React from "react";
import { createReactInlineContentSpec } from "@blocknote/react"; // The Mention inline content.

export const Attribute = createReactInlineContentSpec(
  {
    type: "attribute",
    propSchema: {
      attribute: {
        default: "Unknown",
      },
      sheetItem: {
        default: "Unknown",
      },
      sheet: {
        default: "Unknown",
      },
    },
    content: "none",
  },
  {
    render: (props) => (
      <at-attribute
        class={"d-inline-block"}
        item={props.inlineContent.props.sheetItem}
        sheet={props.inlineContent.props.sheet}
        editable={false}
        attribute={props.inlineContent.props.attribute}
        mode="custom"
      />
    ),

  },
);
