var F = Object.defineProperty;
var Y = (o, e, t) => e in o ? F(o, e, {
  enumerable: !0,
  configurable: !0,
  writable: !0,
  value: t
}) : o[e] = t;
var p = (o, e, t) => Y(o, typeof e != "symbol" ? e + "" : e, t);
import { getNodeById as E, createStronglyTypedTiptapNode as R, createBlockSpecFromStronglyTypedTiptapNode as j, BlockNoteSchema as z, getBlockInfo as Z, nodeToBlock as S, UniqueID as q, getNearestBlockPos as $, insertOrUpdateBlock as O } from "@blocknote/core";
import { Extension as J } from "@tiptap/core";
import { PluginKey as Q, Plugin as H } from "prosemirror-state";
import { DecorationSet as D, Decoration as B } from "prosemirror-view";
import { dropPoint as ee } from "prosemirror-transform";
import { jsx as T } from "react/jsx-runtime";
import v from "react";
const te = {
    slash_menu: {
      two_columns: {
        title: "عمودان",
        subtext: "عمودان جنبًا إلى جنب",
        aliases: ["أعمدة", "صف", "تقسيم"],
        group: "الكتل الأساسية"
      },
      three_columns: {
        title: "ثلاثة أعمدة",
        subtext: "ثلاثة أعمدة جنبًا إلى جنب",
        aliases: ["أعمدة", "صف", "تقسيم"],
        group: "الكتل الأساسية"
      }
    }
  },
  oe = {
    slash_menu: {
      two_columns: {
        title: "Zwei Spalten",
        subtext: "Zwei Spalten nebeneinander",
        aliases: ["Spalten", "Reihe", "teilen"],
        group: "Grundlegende blöcke"
      },
      three_columns: {
        title: "Drei Spalten",
        subtext: "Drei Spalten nebeneinander",
        aliases: ["Spalten", "Reihe", "teilen"],
        group: "Grundlegende blöcke"
      }
    }
  },
  ne = {
    slash_menu: {
      two_columns: {
        title: "Two Columns",
        subtext: "Two columns side by side",
        aliases: ["columns", "row", "split"],
        group: "Basic blocks"
      },
      three_columns: {
        title: "Three Columns",
        subtext: "Three columns side by side",
        aliases: ["columns", "row", "split"],
        group: "Basic blocks"
      }
    }
  },
  ie = {
    slash_menu: {
      two_columns: {
        title: "Dos Columnas",
        subtext: "Dos columnas lado a lado",
        aliases: ["columnas", "fila", "dividir"],
        group: "Bloques básicos"
      },
      three_columns: {
        title: "Tres Columnas",
        subtext: "Tres columnas lado a lado",
        aliases: ["columnas", "fila", "dividir"],
        group: "Bloques básicos"
      }
    }
  },
  se = {
    slash_menu: {
      two_columns: {
        title: "Deux Colonnes",
        subtext: "Deux colonnes côte à côte",
        aliases: ["colonnes", "rangée", "partager"],
        group: "Blocs de base"
      },
      three_columns: {
        title: "Trois Colonnes",
        subtext: "Trois colonnes côte à côte",
        aliases: ["colonnes", "rangée", "partager"],
        group: "Blocs de base"
      }
    }
  },
  re = {
    slash_menu: {
      two_columns: {
        title: "Dva Stupca",
        subtext: "Dva stupca jedan pored drugog",
        aliases: ["stupci", "redak", "podijeli"],
        group: "Osnovni blokovi"
      },
      three_columns: {
        title: "Tri Stupca",
        subtext: "Tri stupca jedan pored drugog",
        aliases: ["stupci", "redak", "podijeli"],
        group: "Osnovni blokovi"
      }
    }
  },
  le = {
    slash_menu: {
      two_columns: {
        title: "Tvær Dálkar",
        subtext: "Tvær dálkar hlið við hlið",
        aliases: ["dálkar", "röð", "skipta"],
        group: "Grunnblokkar"
      },
      three_columns: {
        title: "Þrír Dálkar",
        subtext: "Þrír dálkar hlið við hlið",
        aliases: ["dálkar", "röð", "skipta"],
        group: "Grunnblokkar"
      }
    }
  },
  ce = {
    slash_menu: {
      two_columns: {
        title: "二列",
        subtext: "二列並んで",
        aliases: ["列", "行", "分割"],
        group: "基本ブロック"
      },
      three_columns: {
        title: "三列",
        subtext: "三列並んで",
        aliases: ["列", "行", "分割"],
        group: "基本ブロック"
      }
    }
  },
  ae = {
    slash_menu: {
      two_columns: {
        title: "두 열",
        subtext: "두 열 나란히",
        aliases: ["열", "행", "분할"],
        group: "기본 블록"
      },
      three_columns: {
        title: "세 열",
        subtext: "세 열 나란히",
        aliases: ["열", "행", "분할"],
        group: "기본 블록"
      }
    }
  },
  ue = {
    slash_menu: {
      two_columns: {
        title: "Twee Kolommen",
        subtext: "Twee kolommen naast elkaar",
        aliases: ["kolommen", "rij", "verdelen"],
        group: "Basisblokken"
      },
      three_columns: {
        title: "Drie Kolommen",
        subtext: "Drie kolommen naast elkaar",
        aliases: ["kolommen", "rij", "verdelen"],
        group: "Basisblokken"
      }
    }
  },
  de = {
    slash_menu: {
      two_columns: {
        title: "Dwie Kolumny",
        subtext: "Dwie kolumny obok siebie",
        aliases: ["kolumny", "rząd", "podzielić"],
        group: "Podstawowe bloki"
      },
      three_columns: {
        title: "Trzy Kolumny",
        subtext: "Trzy kolumny obok siebie",
        aliases: ["kolumny", "rząd", "podzielić"],
        group: "Podstawowe bloki"
      }
    }
  },
  he = {
    slash_menu: {
      two_columns: {
        title: "Duas Colunas",
        subtext: "Duas colunas lado a lado",
        aliases: ["colunas", "linha", "dividir"],
        group: "Blocos básicos"
      },
      three_columns: {
        title: "Três Colunas",
        subtext: "Três colunas lado a lado",
        aliases: ["colunas", "linha", "dividir"],
        group: "Blocos básicos"
      }
    }
  },
  me = {
    slash_menu: {
      two_columns: {
        title: "Два Столбца",
        subtext: "Два столбца рядом",
        aliases: ["столбцы", "ряд", "разделить"],
        group: "Базовые блоки"
      },
      three_columns: {
        title: "Три Столбца",
        subtext: "Три столбца рядом",
        aliases: ["столбцы", "ряд", "разделить"],
        group: "Базовые блоки"
      }
    }
  },
  pe = {
    slash_menu: {
      two_columns: {
        title: "Hai Cột",
        subtext: "Hai cột cạnh nhau",
        aliases: ["cột", "hàng", "chia"],
        group: "Khối cơ bản"
      },
      three_columns: {
        title: "Ba Cột",
        subtext: "Ba cột cạnh nhau",
        aliases: ["cột", "hàng", "chia"],
        group: "Khối cơ bản"
      }
    }
  },
  fe = {
    slash_menu: {
      two_columns: {
        title: "两列",
        subtext: "两列并排",
        aliases: ["列", "行", "分割"],
        group: "基础"
      },
      three_columns: {
        title: "三列",
        subtext: "三列并排",
        aliases: ["列", "行", "分割"],
        group: "基础"
      }
    }
  },
  Ae = /* @__PURE__ */Object.freeze( /* @__PURE__ */Object.defineProperty({
    __proto__: null,
    ar: te,
    de: oe,
    en: ne,
    es: ie,
    fr: se,
    hr: re,
    is: le,
    ja: ce,
    ko: ae,
    nl: ue,
    pl: de,
    pt: he,
    ru: me,
    vi: pe,
    zh: fe
  }, Symbol.toStringTag, {
    value: "Module"
  }));
function N(o) {
  if (!o.dictionary.multi_column) throw new Error("Multi-column dictionary not found");
  return o.dictionary.multi_column;
}
const C = new Q("ColumnResizePlugin");
class ge {
  constructor(e, t) {
    p(this, "editor");
    p(this, "view");
    p(this, "RESIZE_MARGIN_WIDTH_PX", 20);
    p(this, "COLUMN_MIN_WIDTH_PERCENT", 0.5);
    p(this, "getColumnHoverOrDefaultState", e => {
      if (!this.editor.isEditable) return {
        type: "default"
      };
      const t = e.target;
      if (!this.view.dom.contains(t)) return {
        type: "default"
      };
      const n = t.closest(".bn-block-column");
      if (!n) return {
        type: "default"
      };
      const r = e.clientX,
        u = n.getBoundingClientRect(),
        c = r < u.left + this.RESIZE_MARGIN_WIDTH_PX ? "left" : r > u.right - this.RESIZE_MARGIN_WIDTH_PX ? "right" : "none",
        l = c === "left" ? n.previousElementSibling : c === "right" ? n.nextElementSibling : void 0;
      if (!l) return {
        type: "default"
      };
      const i = c === "left" ? l : n,
        g = c === "left" ? n : l,
        a = i.getAttribute("data-id"),
        s = g.getAttribute("data-id"),
        d = E(a, this.view.state.doc),
        m = E(s, this.view.state.doc);
      if (!d || !m || !d.posBeforeNode) throw new Error("Column not found");
      return {
        type: "hover",
        leftColumn: {
          element: i,
          id: a,
          ...d
        },
        rightColumn: {
          element: g,
          id: s,
          ...m
        }
      };
    });
    // When the user mouses down near the boundary between two columns, we
    // want to set the plugin state to resize, so the columns can be resized
    // by moving the mouse.
    p(this, "mouseDownHandler", e => {
      let t = this.getColumnHoverOrDefaultState(e);
      if (t.type === "default") return;
      e.preventDefault();
      const n = e.clientX,
        r = t.leftColumn.element.getBoundingClientRect().width,
        u = t.rightColumn.element.getBoundingClientRect().width,
        c = t.leftColumn.node.attrs.width,
        l = t.rightColumn.node.attrs.width;
      t = {
        type: "resize",
        startPos: n,
        leftColumn: {
          ...t.leftColumn,
          widthPx: r,
          widthPercent: c
        },
        rightColumn: {
          ...t.rightColumn,
          widthPx: u,
          widthPercent: l
        }
      }, this.view.dispatch(this.view.state.tr.setMeta(C, t)), this.editor.sideMenu.freezeMenu();
    });
    // If the plugin isn't in a resize state, we want to update it to either a
    // hover state if the mouse is near the boundary between two columns, or
    // default otherwise. If the plugin is in a resize state, we want to
    // update the column widths based on the horizontal mouse movement.
    p(this, "mouseMoveHandler", e => {
      var i, g;
      const t = C.getState(this.view.state);
      if (!t) return;
      if (t.type !== "resize") {
        const a = this.getColumnHoverOrDefaultState(e),
          s = t.type === "default" && a.type === "default",
          d = t.type !== "default" && a.type !== "default" && t.leftColumn.id === a.leftColumn.id && t.rightColumn.id === a.rightColumn.id;
        if (s || d || a.type === "hover" && (g = (i = this.editor.sideMenu.view) == null ? void 0 : i.state) != null && g.show) return;
        this.view.dispatch(this.view.state.tr.setMeta(C, a));
        return;
      }
      const r = (e.clientX - t.startPos) * t.leftColumn.widthPercent,
        u = (t.leftColumn.widthPx + r) / t.leftColumn.widthPx - 1;
      let c = t.leftColumn.widthPercent + u,
        l = t.rightColumn.widthPercent - u;
      c < this.COLUMN_MIN_WIDTH_PERCENT ? (l -= this.COLUMN_MIN_WIDTH_PERCENT - c, c = this.COLUMN_MIN_WIDTH_PERCENT) : l < this.COLUMN_MIN_WIDTH_PERCENT && (c -= this.COLUMN_MIN_WIDTH_PERCENT - l, l = this.COLUMN_MIN_WIDTH_PERCENT), this.view.dispatch(this.view.state.tr.setNodeAttribute(t.leftColumn.posBeforeNode, "width", c).setNodeAttribute(t.rightColumn.posBeforeNode, "width", l).setMeta("addToHistory", !1));
    });
    // If the plugin is in a resize state, we want to revert it to a default
    // or hover, depending on where the mouse cursor is, when the user
    // releases the mouse button.
    p(this, "mouseUpHandler", e => {
      const t = C.getState(this.view.state);
      if (!t || t.type !== "resize") return;
      const n = this.getColumnHoverOrDefaultState(e);
      this.view.dispatch(this.view.state.tr.setMeta(C, n)), this.editor.sideMenu.unfreezeMenu();
    });
    this.editor = e, this.view = t, this.view.dom.addEventListener("mousedown", this.mouseDownHandler), document.body.addEventListener("mousemove", this.mouseMoveHandler), document.body.addEventListener("mouseup", this.mouseUpHandler);
  }
  destroy() {
    this.view.dom.removeEventListener("mousedown", this.mouseDownHandler), document.body.removeEventListener("mousemove", this.mouseMoveHandler), document.body.removeEventListener("mouseup", this.mouseUpHandler);
  }
}
const be = o => new H({
    key: C,
    props: {
      // This adds a border between the columns when the user is
      // resizing them or when the cursor is near their boundary.
      decorations: e => {
        const t = C.getState(e);
        return !t || t.type === "default" ? D.empty : D.create(e.doc, [B.node(t.leftColumn.posBeforeNode, t.leftColumn.posBeforeNode + t.leftColumn.node.nodeSize, {
          style: "box-shadow: 4px 0 0 #ccc; cursor: col-resize"
        }), B.node(t.rightColumn.posBeforeNode, t.rightColumn.posBeforeNode + t.rightColumn.node.nodeSize, {
          style: "cursor: col-resize"
        })]);
      }
    },
    state: {
      init: () => ({
        type: "default"
      }),
      apply: (e, t) => {
        const n = e.getMeta(C);
        return n === void 0 ? t : n;
      }
    },
    view: e => new ge(o, e)
  }),
  we = o => J.create({
    name: "columnResize",
    addProseMirrorPlugins() {
      return [be(o)];
    }
  }),
  Ce = R({
    name: "column",
    group: "bnBlock childContainer",
    // A block always contains content, and optionally a blockGroup which contains nested blocks
    content: "blockContainer+",
    priority: 40,
    defining: !0,
    addAttributes() {
      return {
        width: {
          // Why does each column have a default width of 1, i.e. 100%? Because
          // when creating a new column, we want to make sure that existing
          // column widths are preserved, while the new one also has a sensible
          // width. If we'd set it so all column widths must add up to 100%
          // instead, then each time a new column is created, we'd have to assign
          // it a width depending on the total number of columns and also adjust
          // the widths of the other columns. The same can be said for using px
          // instead of percent widths and making them add to the editor width. So
          // using this method is both simpler and computationally cheaper. This
          // is possible because we can set the `flex-grow` property to the width
          // value, which handles all the resizing for us, instead of manually
          // having to set the `width` property of each column.
          default: 1,
          parseHTML: o => {
            const e = o.getAttribute("data-width");
            if (e === null) return null;
            const t = parseFloat(e);
            return isFinite(t) ? t : null;
          },
          renderHTML: o => ({
            "data-width": o.width.toString(),
            style: `flex-grow: ${o.width};`
          })
        }
      };
    },
    parseHTML() {
      return [{
        tag: "div",
        getAttrs: o => typeof o == "string" ? !1 : o.getAttribute("data-node-type") === this.name ? {} : !1
      }];
    },
    renderHTML({
      HTMLAttributes: o
    }) {
      const e = document.createElement("div");
      e.className = "bn-block-column", e.setAttribute("data-node-type", this.name);
      for (const [t, n] of Object.entries(o)) e.setAttribute(t, n);
      return {
        dom: e,
        contentDOM: e
      };
    },
    addExtensions() {
      return [we(this.options.editor)];
    }
  }),
  ve = R({
    name: "columnList",
    group: "childContainer bnBlock blockGroupChild",
    // A block always contains content, and optionally a blockGroup which contains nested blocks
    content: "column column+",
    // min two columns
    priority: 40,
    // should be below blockContainer
    defining: !0,
    parseHTML() {
      return [{
        tag: "div",
        getAttrs: o => typeof o == "string" ? !1 : o.getAttribute("data-node-type") === this.name ? {} : !1
      }];
    },
    renderHTML({
      HTMLAttributes: o
    }) {
      const e = document.createElement("div");
      e.className = "bn-block-column-list", e.setAttribute("data-node-type", this.name);
      for (const [t, n] of Object.entries(o)) e.setAttribute(t, n);
      return e.style.display = "flex", {
        dom: e,
        contentDOM: e
      };
    }
  }),
  W = j(Ce, {
    width: {
      default: 1
    }
  }),
  A = j(ve, {}),
  M = z.create({
    blockSpecs: {
      column: W,
      columnList: A
    }
  }),
  Ve = o => z.create({
    blockSpecs: {
      ...o.blockSpecs,
      column: W,
      columnList: A
    },
    inlineContentSpecs: o.inlineContentSpecs,
    styleSpecs: o.styleSpecs
  }),
  k = 0.1;
function ye(o) {
  return {
    left: o.clientX,
    top: o.clientY
  };
}
function Xe(o) {
  const e = o.editor;
  return new H({
    view(t) {
      return new ke(t, o);
    },
    props: {
      handleDrop(t, n, r, u) {
        const c = t.posAtCoords(ye(n));
        if (!c) throw new Error("Could not get event position");
        const l = V(t.state, c),
          i = Z(l),
          a = t.nodeDOM(l.posBeforeNode).getBoundingClientRect();
        let s = "regular";
        if (n.clientX <= a.left + a.width * k && (s = "left"), n.clientX >= a.right - a.width * k && (s = "right"), s === "regular") return !1;
        const d = S(r.content.child(0), e.schema.blockSchema, e.schema.inlineContentSchema, e.schema.styleSchema
        // TODO: cache?
        );
        if (i.blockNoteType === "column") {
          const m = t.state.doc.resolve(i.bnBlock.beforePos).node(),
            h = S(m, e.schema.blockSchema, e.schema.inlineContentSchema, e.schema.styleSchema);
          let f = 0;
          h.children.forEach(b => {
            f += b.props.width;
          });
          const w = f / h.children.length;
          if (w < 0.99 || w > 1.01) {
            const b = 1 / w;
            h.children.forEach(y => {
              y.props.width = y.props.width * b;
            });
          }
          const x = h.children.findIndex(b => b.id === i.bnBlock.node.attrs.id),
            K = h.children.map(b => ({
              ...b,
              children: b.children.filter(y => y.id !== d.id)
            })).filter(b => b.children.length > 0).toSpliced(s === "left" ? x : x + 1, 0, {
              type: "column",
              children: [d],
              props: {},
              content: void 0,
              id: q.options.generateID()
            });
          e.removeBlocks([d]), e.updateBlock(h, {
            children: K
          });
        } else {
          const m = S(i.bnBlock.node, e.schema.blockSchema, e.schema.inlineContentSchema, e.schema.styleSchema),
            h = s === "left" ? [d, m] : [m, d];
          e.removeBlocks([d]), e.replaceBlocks([m], [{
            type: "columnList",
            children: h.map(f => ({
              type: "column",
              children: [f]
            }))
          }]);
        }
        return !0;
      }
    }
  });
}
class ke {
  constructor(e, t) {
    p(this, "width");
    p(this, "color");
    p(this, "class");
    p(this, "cursorPos");
    p(this, "element", null);
    p(this, "timeout");
    p(this, "handlers");
    this.editorView = e, this.width = t.width ?? 1, this.color = t.color === !1 ? void 0 : t.color || "black", this.class = t.class, this.handlers = ["dragover", "dragend", "drop", "dragleave"].map(n => {
      const r = u => {
        this[n](u);
      };
      return e.dom.addEventListener(n, r,
      // drop event captured in bubbling phase to make sure
      // "cursorPos" is set to undefined before the "handleDrop" handler is called
      // (otherwise an error could be thrown, see https://github.com/TypeCellOS/BlockNote/pull/1240)
      n === "drop" ? !0 : void 0), {
        name: n,
        handler: r
      };
    });
  }
  destroy() {
    this.handlers.forEach(({
      name: e,
      handler: t
    }) => this.editorView.dom.removeEventListener(e, t, e === "drop" ? !0 : void 0));
  }
  update(e, t) {
    this.cursorPos != null && t.doc !== e.state.doc && (this.cursorPos.pos > e.state.doc.content.size ? this.setCursor(void 0) : this.updateOverlay());
  }
  setCursor(e) {
    var t, n;
    e === this.cursorPos || (e == null ? void 0 : e.pos) === ((t = this.cursorPos) == null ? void 0 : t.pos) && (e == null ? void 0 : e.position) === ((n = this.cursorPos) == null ? void 0 : n.position) || (this.cursorPos = e, e ? this.updateOverlay() : (this.element.parentNode.removeChild(this.element), this.element = null));
  }
  updateOverlay() {
    if (!this.cursorPos) throw new Error("updateOverlay called with no cursor position");
    const e = this.editorView.state.doc.resolve(this.cursorPos.pos),
      t = !e.parent.inlineContent;
    let n;
    const r = this.editorView.dom,
      u = r.getBoundingClientRect(),
      c = u.width / r.offsetWidth,
      l = u.height / r.offsetHeight;
    if (t) {
      const s = e.nodeBefore,
        d = e.nodeAfter;
      if (s || d) if (this.cursorPos.position === "left" || this.cursorPos.position === "right") {
        const m = this.editorView.nodeDOM(this.cursorPos.pos);
        if (!m) throw new Error("nodeDOM returned null in updateOverlay");
        const h = m.getBoundingClientRect(),
          f = this.width / 2 * l,
          w = this.cursorPos.position === "left" ? h.left : h.right;
        n = {
          left: w - f,
          right: w + f,
          top: h.top,
          bottom: h.bottom
          // left: blockRect.left,
          // right: blockRect.right,
        };
      } else {
        const m = this.editorView.nodeDOM(this.cursorPos.pos - (s ? s.nodeSize : 0));
        if (m) {
          const h = m.getBoundingClientRect();
          let f = s ? h.bottom : h.top;
          s && d && (f = (f + this.editorView.nodeDOM(this.cursorPos.pos).getBoundingClientRect().top) / 2);
          const w = this.width / 2 * l;
          this.cursorPos.position === "regular" && (n = {
            left: h.left,
            right: h.right,
            top: f - w,
            bottom: f + w
          });
        }
      }
    }
    if (!n) {
      const s = this.editorView.coordsAtPos(this.cursorPos.pos),
        d = this.width / 2 * c;
      n = {
        left: s.left - d,
        right: s.left + d,
        top: s.top,
        bottom: s.bottom
      };
    }
    const i = this.editorView.dom.offsetParent;
    this.element || (this.element = i.appendChild(document.createElement("div")), this.class && (this.element.className = this.class), this.element.style.cssText = "position: absolute; z-index: 50; pointer-events: none;", this.color && (this.element.style.backgroundColor = this.color)), this.element.classList.toggle("prosemirror-dropcursor-block", t), this.element.classList.toggle("prosemirror-dropcursor-vertical", this.cursorPos.position !== "regular"), this.element.classList.toggle("prosemirror-dropcursor-inline", !t);
    let g, a;
    if (!i || i === document.body && getComputedStyle(i).position === "static") g = -window.scrollX, a = -window.scrollY;else {
      const s = i.getBoundingClientRect(),
        d = s.width / i.offsetWidth,
        m = s.height / i.offsetHeight;
      g = s.left - i.scrollLeft * d, a = s.top - i.scrollTop * m;
    }
    this.element.style.left = (n.left - g) / c + "px", this.element.style.top = (n.top - a) / l + "px", this.element.style.width = (n.right - n.left) / c + "px", this.element.style.height = (n.bottom - n.top) / l + "px";
  }
  scheduleRemoval(e) {
    clearTimeout(this.timeout), this.timeout = setTimeout(() => this.setCursor(void 0), e);
  }
  // this gets executed on every mouse move when dragging (drag over)
  dragover(e) {
    if (!this.editorView.editable) return;
    const t = this.editorView.posAtCoords({
        left: e.clientX,
        top: e.clientY
      }),
      n = t && t.inside >= 0 && this.editorView.state.doc.nodeAt(t.inside),
      r = n && n.type.spec.disableDropCursor,
      u = typeof r == "function" ? r(this.editorView, t, e) : r;
    if (t && !u) {
      let c = "regular",
        l = t.pos;
      const i = V(this.editorView.state, t),
        a = this.editorView.nodeDOM(i.posBeforeNode).getBoundingClientRect();
      if (e.clientX <= a.left + a.width * k && (c = "left", l = i.posBeforeNode), e.clientX >= a.right - a.width * k && (c = "right", l = i.posBeforeNode), c === "regular" && this.editorView.dragging && this.editorView.dragging.slice) {
        const s = ee(this.editorView.state.doc, l, this.editorView.dragging.slice);
        s != null && (l = s);
      }
      this.setCursor({
        pos: l,
        position: c
      }), this.scheduleRemoval(5e3);
    }
  }
  dragend() {
    this.scheduleRemoval(20);
  }
  drop() {
    this.setCursor(void 0);
  }
  dragleave(e) {
    (e.target === this.editorView.dom || !this.editorView.dom.contains(e.relatedTarget)) && this.setCursor(void 0);
  }
}
function V(o, e) {
  const t = $(o.doc, e.pos);
  let n = o.doc.resolve(t.posBeforeNode);
  return n.parent.type.name === "column" && (n = o.doc.resolve(n.before())), {
    posBeforeNode: n.pos,
    node: n.nodeAfter
  };
}
var X = {
    color: void 0,
    size: void 0,
    className: void 0,
    style: void 0,
    attr: void 0
  },
  I = v.createContext && /* @__PURE__ */v.createContext(X),
  _e = ["attr", "size", "title"];
function Pe(o, e) {
  if (o == null) return {};
  var t = Se(o, e),
    n,
    r;
  if (Object.getOwnPropertySymbols) {
    var u = Object.getOwnPropertySymbols(o);
    for (r = 0; r < u.length; r++) n = u[r], !(e.indexOf(n) >= 0) && Object.prototype.propertyIsEnumerable.call(o, n) && (t[n] = o[n]);
  }
  return t;
}
function Se(o, e) {
  if (o == null) return {};
  var t = {};
  for (var n in o) if (Object.prototype.hasOwnProperty.call(o, n)) {
    if (e.indexOf(n) >= 0) continue;
    t[n] = o[n];
  }
  return t;
}
function _() {
  return _ = Object.assign ? Object.assign.bind() : function (o) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var n in t) Object.prototype.hasOwnProperty.call(t, n) && (o[n] = t[n]);
    }
    return o;
  }, _.apply(this, arguments);
}
function L(o, e) {
  var t = Object.keys(o);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(o);
    e && (n = n.filter(function (r) {
      return Object.getOwnPropertyDescriptor(o, r).enumerable;
    })), t.push.apply(t, n);
  }
  return t;
}
function P(o) {
  for (var e = 1; e < arguments.length; e++) {
    var t = arguments[e] != null ? arguments[e] : {};
    e % 2 ? L(Object(t), !0).forEach(function (n) {
      xe(o, n, t[n]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(o, Object.getOwnPropertyDescriptors(t)) : L(Object(t)).forEach(function (n) {
      Object.defineProperty(o, n, Object.getOwnPropertyDescriptor(t, n));
    });
  }
  return o;
}
function xe(o, e, t) {
  return e = Ee(e), e in o ? Object.defineProperty(o, e, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : o[e] = t, o;
}
function Ee(o) {
  var e = Oe(o, "string");
  return typeof e == "symbol" ? e : e + "";
}
function Oe(o, e) {
  if (typeof o != "object" || !o) return o;
  var t = o[Symbol.toPrimitive];
  if (t !== void 0) {
    var n = t.call(o, e || "default");
    if (typeof n != "object") return n;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (e === "string" ? String : Number)(o);
}
function U(o) {
  return o && o.map((e, t) => /* @__PURE__ */v.createElement(e.tag, P({
    key: t
  }, e.attr), U(e.child)));
}
function G(o) {
  return e => /* @__PURE__ */v.createElement(De, _({
    attr: P({}, o.attr)
  }, e), U(o.child));
}
function De(o) {
  var e = t => {
    var {
        attr: n,
        size: r,
        title: u
      } = o,
      c = Pe(o, _e),
      l = r || t.size || "1em",
      i;
    return t.className && (i = t.className), o.className && (i = (i ? i + " " : "") + o.className), /* @__PURE__ */v.createElement("svg", _({
      stroke: "currentColor",
      fill: "currentColor",
      strokeWidth: "0"
    }, t.attr, n, c, {
      className: i,
      style: P(P({
        color: o.color || t.color
      }, t.style), o.style),
      height: l,
      width: l,
      xmlns: "http://www.w3.org/2000/svg"
    }), u && /* @__PURE__ */v.createElement("title", null, u), o.children);
  };
  return I !== void 0 ? /* @__PURE__ */v.createElement(I.Consumer, null, t => e(t)) : e(X);
}
function Be(o) {
  return G({
    tag: "svg",
    attr: {
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      strokeWidth: "2",
      strokeLinecap: "round",
      strokeLinejoin: "round"
    },
    child: [{
      tag: "path",
      attr: {
        d: "M3 3m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v16a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1zm9 -1v18"
      },
      child: []
    }]
  })(o);
}
function Te(o) {
  return G({
    tag: "svg",
    attr: {
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      strokeWidth: "2",
      strokeLinecap: "round",
      strokeLinejoin: "round"
    },
    child: [{
      tag: "path",
      attr: {
        d: "M3 3m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v16a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1zm6 -1v18m6 -18v18"
      },
      child: []
    }]
  })(o);
}
function Ne(o) {
  return "column" in o.schema.blockSchema && o.schema.blockSchema.columnList === M.blockSchema.columnList && "column" in o.schema.blockSchema && o.schema.blockSchema.column === M.blockSchema.column;
}
function Ue(o) {
  const e = [];
  return Ne(o) && e.push({
    ...N(o).slash_menu.two_columns,
    icon: /* @__PURE__ */T(Be, {
      size: 18
    }),
    onItemClick: () => {
      O(o, {
        type: "columnList",
        children: [{
          type: "column",
          children: [{
            type: "paragraph"
          }]
        }, {
          type: "column",
          children: [{
            type: "paragraph"
          }]
        }]
      });
    }
  }, {
    ...N(o).slash_menu.three_columns,
    icon: /* @__PURE__ */T(Te, {
      size: 18
    }),
    onItemClick: () => {
      O(o, {
        type: "columnList",
        children: [{
          type: "column",
          children: [{
            type: "paragraph"
          }]
        }, {
          type: "column",
          children: [{
            type: "paragraph"
          }]
        }, {
          type: "column",
          children: [{
            type: "paragraph"
          }]
        }]
      });
    }
  }), e;
}
export { W as ColumnBlock, A as ColumnListBlock, Ne as checkMultiColumnBlocksInSchema, N as getMultiColumnDictionary, Ue as getMultiColumnSlashMenuItems, Ae as locales, Xe as multiColumnDropCursor, M as multiColumnSchema, Ve as withMultiColumn };
