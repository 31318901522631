import {Component, OnInit} from '@angular/core';
import {UrlUtil} from "../share/utils/url.util";
import {BasicsRepository} from "../data/repository/basics.repository";

@Component({
  selector: 'atw-redirect',
  templateUrl: './redirect.component.html',
  styleUrl: './redirect.component.scss',
})
export class RedirectComponent implements OnInit {

  constructor(private urlUtils:UrlUtil,private baseRepo:BasicsRepository) {
  }


  async ngOnInit() {
    let params = this.urlUtils.getQueryParams();
    let access = params['accessToken'];
    let refresh = params['refreshToken'];
    await this.baseRepo.initFromAccessToken(access,refresh);
  }
}
